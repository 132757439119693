.selectBlockMain {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  width: 400px;
  @media screen and (max-width: 500px) {
    width: 100%;
    border-radius: 5px;
  }

  .selectBlock {
    position: relative;
    border-radius: 8px;
    width: 400px;
    height: 40px; 
    @media screen and (max-width: 500px) {
      width: 100%;
      height: 40px;
      border-radius: 5px;
    }

    &__inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      width: 100%;
      height: 50px;
      padding: 2px 20px;
      border: none;
      border-radius: 10px;
      border: 1px solid #cdcdcd;
      backdrop-filter: blur(15px);
      color: #344050;
      background: #fff;
      @media screen and (max-width: 500px) {
        height: 40px;
        border-radius: 5px;
      }

      .rotate0 {
        transform: rotate(180deg);
        width: 25px;
        height: 25px;
      }

      .rotate180 {
        transform: rotate(0deg);
        width: 25px;
        height: 25px;
      }

      p {
        font-size: 18px;
        font-weight: 400;
        line-height: 20px;
        color: #cdcdcd;
        padding: 0;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        @media screen and (max-width: 500px) {
          font-size: 15px;
        }
      }

      .activeText {
        font-size: 18px;
        font-weight: 400;
        line-height: 20px;
        color: #050505;
        @media screen and (max-width: 500px) {
          font-size: 15px;
        }
      }

      img {
        width: 20px;
        height: 20px;
        display: block;
        transform: rotate(270deg);
        transition: 0.5s;
      }
    }

    .more {
      border-radius: 10px 10px 0 0;
      border: none;
      box-shadow: 0 1px 5px 0 rgba(65, 69, 88, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
    }

    &__activeBlock {
      animation: modal 0.5s ease-in-out;
      position: absolute;
      left: 0;
      top: 45px;
      z-index: 10;
      background-color: #fff;
      border-radius: 0 0 5px 5px;
      width: 100%;
      max-height: 220px;
      overflow-y: scroll;
      box-shadow: 0 1px 5px 0 rgba(65, 69, 88, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);;
      padding: 5px 20px;
      &::-webkit-scrollbar {
          width: 0px;
        }

      @media screen and (max-width: 500px) {
        max-height: 310px;
      }

      p {
        font-size: 18px;
        font-weight: 400;
        line-height: 20px;
        color: #050505;
        cursor: pointer;
        padding: 10px 0px;
        @media screen and (max-width: 500px) {
          font-size: 15px;
        }
      }
      // p:hover {
      //   background-color: #5e6e8257;
      //   color: #fff !important;
      // }

      p:first-child{
        border-radius: 8px 8px 0 0;
      }
      p:last-child{
        border-radius: 0 0 8px 8px;
      }
      
    }
  }
}


@keyframes modal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}