.listDatesPeriod {
  &__inner {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h6 {
      font-size: 16px;
    }

    button {
      font-size: 19px;
      font-weight: 500;
      color: rgba(47, 71, 190, 0.591);
      text-decoration: underline;
      background: transparent;
    }
  }

  .actionsDate {
    width: 90%;
    background: #77a64b;
    color: #fff;
    font-size: 16px;
    border-radius: 6px;
    position: absolute;
    bottom: 25px;
    height: 40px;
    left: 50%;
    transform: translateX(-50%);
  }
}
.blockDate {
  height: 100vh;
  min-height: 100vh;
  min-width: 100%;
  position: absolute;
  background: #00000083;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .shadowDate {
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__inner {
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .actionsDate {
      position: absolute;
      bottom: 15px;
      width: 100%;
      right: 10px;
      padding: 8px 8px 10px;
      border-radius: 5px;
      background: #77a64b;
      width: 90px;
      color: #fff;
      font-size: 15px;
      height: 34px;
    }
    .react-datepicker {
      height: 380px !important;
    }
  }
}
