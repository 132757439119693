.returnMainBlock {
    min-height: 100vh;

    &__title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 10px;
        padding-top: 12px;
        padding-bottom: 12px;
        background-color: rgba(47, 71, 190, 0.287);
        margin-bottom: 0;

        p {
            font-size: 18px;
            font-weight: 500;
            color: #fff;
        }

        > div {
            border-top: 3px solid #fff;
            border-right: 3px solid #fff;
            height: 15px;
            width: 15px;
            border-radius: 3px;
            transform: rotate(45deg);
            margin-right: 20px;
            margin-top: 5px;
        }
    }

    .listBlock {
        min-width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding-bottom: 110px;
      }
      
}