.navActions {
  display: flex;
  background: green;
  min-width: 100%;
  justify-content: space-between;
}

.parentBlock {
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 10px;

  .balance {
    width: 100%;
    margin: 0 auto 10px auto;
    padding: 10px 15px;
    background-color: rgba(47, 71, 190, 0.591);
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    &__inner {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;

      > div {
        border-top: 3px solid #fff;
        border-right: 3px solid #fff;
        height: 10px;
        width: 10px;
        border-radius: 3px;
        transform: rotate(45deg);
        margin-right: 20px;
      }
      p {
        line-height: 18px;
        font-weight: 700;
        color: #fff;
        font-size: 17px;
      }
    }

    &__num {
      font-weight: 500;
      color: #fff;
      font-size: 16px;
      margin-top: 8px;
    }

    &__history {
      color: #fff;
      font-size: 18px;
      line-height: 20px;
    }
  }
}

.allCateg {
  min-width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 8px 8px;
  padding: 0 0 30px 0;

  > button:nth-child(4) {
    ///// для продаж через телефон
    display: none;
    @media screen and (max-width: 540px) {
      display: block;
    }
  }

  > button:nth-child(5) {
    ///// для продаж через комп
    display: block;
    @media screen and (max-width: 540px) {
      display: none;
    }
  }
}
