.containerPay {
    min-height: 100vh;

    > button {
        display: block;
        font-size: 16px;
        color: #fff;
        min-width: 95%;
        padding: 15px 0px;
        border-radius: 8px;
        font-weight: 600;
        background-color: rgba(97, 100, 239, 0.7);
        margin: 15px auto;
    }

    .titlePay {
        padding: 10px 8px;
        font-size: 18px;
        font-weight: 500;
        background-color: rgba(47, 71, 190, 0.591);
        color: #fff;
    }

    .listPay {
        padding: 0px 0 40px;
    }
}