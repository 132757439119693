.blockEveryMain {
    min-width: 100%;
    border-radius: 5px;
    border-bottom: 2px solid rgb(217, 223, 232);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    background-color: rgba(162, 178, 238, 0.102);

    &__inner {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 99%;
        align-items: center;
        
        .mainContent {
          display: flex;
          flex-direction: row;
        }
        
        .titleEvery {
          font-size: 16px;
          font-weight: 400;
          color: #222;
        }
        
        .width85 {
          width: 85%;
          margin: 0 0 0 3px,
        }

        .arrow {
            border-top: 3px solid rgba(162, 178, 238, 0.439);
            border-right: 3px solid rgba(162, 178, 238, 0.439);
            min-height: 15px;
            min-width: 15px;
        }
    }
  }
  