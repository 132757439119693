.mainRevisionParent {
    .actionBlock {
        display: flex;
        flex-direction: row;
        justify-content: center;
        min-width: 100%;
        gap: 20px;

        button {
            font-size: 13px;
            color: #fff;
            width: 43%;
            padding: 10px 8px;
            border-radius: 8px;
            font-weight: 600;
            background-color: rgba(97, 100, 239, 0.7);
            margin: 12px 0px;
        }
    }

    > h6 {
        padding: 10px;
        font-size: 17px;
        font-weight: 500;
        margin-bottom: 3px;
        background-color: #fff;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    }

    &__list {
        margin: 3px 0 60px 0;
    }
}