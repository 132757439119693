.modalInnerPay {
    width: 400px;
    background-color: #ebeef2;
    padding: 15px;
    border-radius: 6px;
    @media screen and (max-width: 430px) {
        width: 360px;
        @media screen and (max-width: 380px) {
            width: 330px;
        }
    }

    > h6 {
        font-size: 17px;
        font-weight: 500;
        color: #222;
    }

    .choiceSelectBlock {
        margin: 10px 0 5px;
        border: 1px solid rgb(217, 223, 232); 
        border-radius: 8px;
        background-color: #f0f0f0;
        min-height: 40px;
        max-height: 180px;
        overflow-y: scroll;
        > button {
            margin: 1px 0px;
        }
        
    }

    .inputsPay {
        input {
            border: 1px solid rgb(217, 223, 232); 
            border-radius: 8px;
            padding: 8px 10px 8px 15px;
            font-size: 16px;
            background-color: #fff;
            width: 100%;
            height: 50px;

            &::placeholder {
                font-size: 14px;
            }
        }

        textarea {
            border: 1px solid rgb(217, 223, 232);
            height: 60px;
            border-radius: 8px;
            padding: 10px;
            padding-left: 15px;
            margin-top: 10px;
            height: 120px;
            font-size: 16px;
            background-color: #fff;
            width: 100%;
        }

        button {
            background-color: rgba(12, 169, 70, 0.9);
            color: #fff;
            min-width: 100%;
            padding: 13px 10px;
            border-radius: 8px;
            font-weight: 600;
            border: 1px solid rgb(217, 223, 232); 
            margin-top: 20px;
        }
    }
}