.logoutParent {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
  }
  
  .logoutInner {
    position: relative;
    width: 70%;
    height: 70%;
    border: 4px solid rgba(47, 71, 190, 0.591);
    border-radius: 20px;
    display: flex;
    align-items: center;
  }
  
  .lineLogOut {
    background-color: #fff;
    padding: 3px 4px;
    position: absolute;
    top: -9px;
    align-self: center;
    left: 50%;
    transform: translateX(-50%);

    &__inner {
      width: 5px;
      height: 17px;
      background-color: rgba(47, 71, 190, 0.591);
      border-radius: 5px;
    }
  }