.actionAccept {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 10px;
  background-color: rgba(47, 71, 190, 0.287);
  width: 100%;

  p {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
  }
}