
.soputkaParent {
    .title {
        padding: 12px;
        font-size: 18px;
        font-weight: 500;
        padding-bottom: 10px;
        padding-top: 10px;
        background-color: rgba(47, 71, 190, 0.591);
        color: #fff;
        margin-bottom: 5px;
        border-radius: 0px !important;
      }
      
      .selectBlock {
        height: 87%;
      }
      
      .soputkaBlock {
        text-align: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        min-width: 100%;
      }
      
      .soputka {
        font-size: 18px;
        color: #fff;
        min-width: 95%;
        padding-top: 13px;
        padding-bottom: 13px;
        border-radius: 8px;
        font-weight: 600;
        background-color: rgba(97, 100, 239, 0.7);
        margin-top: 15px;
        margin-bottom: 15px;
      }
}
  