.selectBlockBottomSheet {
    margin: 10px 10px 10px 10px;
    border-radius: 5px;
    background-color: #fff;
    padding-bottom: 20px;

    > button {
        min-width: 100%;
        padding: 10px;
        border: 1px solid rgb(217, 223, 232);
        background-color: rgba(199, 210, 254, 0.718);
        border-radius: 3px;
        margin: 1px 0px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-right: 15px;
        align-items: center;

        p {
            font-size: 15px;
            font-weight: 600;
            color: rgba(47, 71, 190, 0.672);
            max-width: 90%;
        }

        .arrow {
            border-top: 3px solid rgba(47, 71, 190, 0.672);
            border-right: 3px solid rgba(47, 71, 190, 0.672);
        }
    }
  }

