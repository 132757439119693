.actionSettings {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  background: #fff;
  box-shadow: 0 0 #000, 0 0 #0000, 5px 0 25px 0 #5e5c9a1a;
  padding: 20px 0px;
  z-index: 9;

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 260px;
    min-width: 60px;
    max-width: 260px;
    overflow: hidden;
    transition: max-width 0.7s ease, padding 0.5s ease;

    h1 {
      height: 35px;
      width: 100%;
      padding: 0 20px;
      margin: 0 0 20px 0;
      position: relative;

      > img {
        width: 130px;
        transition: opacity 0.1s ease;
      }

      button {
        background: transparent;
        position: absolute;
        right: 10px;
        top: 15%;
        transition: transform 0.5s ease;

        img {
          width: 25px;
          height: 25px;
        }

        .rotate {
          transform: rotate(180deg);
        }
      }
    }

    .activeLogo {
      > img {
        opacity: 0;
      }

      button {
        right: 50%;
        transform: translateX(50%);
      }
    }

    .menuBlocks {
      background: #e0e6ed4d !important;
      transition: font-size 0.5s ease, padding 0.5s ease;

      span {
        font-size: 14px;
        font-weight: 600;
      }
    }

    .menuBlocksActive {
      padding: 10px;

      span {
        font-size: 13px;
        font-weight: 600;
      }
    }

    > div {
      padding: 10px 20px;
      margin: 3px 0px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      gap: 5px;
      transition: background 0.7s ease, opacity 0.7s ease;
      position: relative;

      &:hover {
        background: #00000014;
      }

      button {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 100%;
          height: 100%;
          color: #506690;
        }
      }
      p {
        font-size: 14px;
        font-weight: 500;
        color: #0e1726;
        transition: opacity 0.7s ease;
      }

      .navArrow {
        width: 18px;
        height: 18px;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .activeMenu {
      background: #00000014;
      box-shadow: 0px 2px 100px rgba(0, 0, 0, 0.1);
    }
  }

  .activeHeader {
    max-width: 70px;
    padding: 0;
    transition: max-width 0.5s ease, padding 0.5s ease;
  }
}
