.parentCateg {
    width: 45%;
    min-width: 45%;
    height: 180px;
    border-radius: 8px;
    position: relative;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }
  
  .shadow {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(15, 15, 16, 0.064);
    z-index: 10;
    border-radius: 8px;
  }
  
  .main {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  
  .textTitle {
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-weight: 600;
    padding: 5px 10px;
    background-color: rgba(199, 210, 254, 0.25);
    color: #222;
    width: 100%;
    text-align: center;
    border-radius: 8px;
  }
  
  .backgroundImage {
    width: 100%;
    height: 140px;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 8px;
    object-fit: contain;
  }
  