.paretnInput {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90vh;

    > div {
        min-width: 100%;
    }
  }
  
  .loginBtn {
    background-color: #e5322d;
    min-width: 100%;
    color: #fff;
    margin-top: 40px;
    padding: 15px 10px;
    border-radius: 8px;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
  }
  
  .logoBlock {
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .logo {
    width: 200px;
    height: 100px;
    object-fit: contain;
    margin-bottom: 20px;
  }
  
  .textInput {
    padding: 10px 2px 0px;
    font-size: 20px;
    text-align: left;
    font-weight: 400;
  }
  
  .input {
    min-width: 100%;
    height: 50px;
    border-radius: 5px;
    margin: 10px 0px;
    align-self: center;
    background-color: #fff;
    padding: 0 12px;
    font-size: 18px;
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  }
  