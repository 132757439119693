.krest {
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    position: absolute;
    right: 0;
    top: 2px;

    &__inner {
      position: relative;
      width: 22px;
      height: 22px;

      .line {
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: red;
      }
      
      .deg {
        transform: rotate(45deg);
        top: 10px;
      }
      
      .degMinus {
        transform: rotate(-45deg);
        top: 10px;
      }
    }
  }
  