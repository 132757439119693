.parentTable {
    max-height: 75%;
    padding-bottom: 160px;
    
    .mainBlock {
      padding: 10px 7px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid rgba(47, 71, 190, 0.287);;
    }
    
    .moreTable {
      padding: 20px 7px;
      background-color: rgba(212, 223, 238, 0.47);
      p {
            font-weight: 600;
            color: #000;
            font-size: 14px;
        }
    }
    
    .name {
      font-size: 13px;
      font-weight: 400;
      color: #222;
      width: 55%;
      padding-right: 15px;
    }
    
    .price {
      font-size: 13px;
      font-weight: 400;
      color: #222;
      width: 22%;
      padding-right: 10px;
    }
    
    .count {
      font-size: 13px;
      font-weight: 400;
      color: #222;
      width: 21%;
    }
  }
  
  