.modal {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  width: 100%;
  height: 100vh;
  animation: animationBlock 0.5s ease-in-out;

  &__shadow {
    position: absolute;
    z-index: 98;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.767);
    cursor: pointer;
  }

  &__inner {
    position: absolute;
    z-index: 99;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);

    opacity: 1;
    transition: background-color 0.5s, opacity 0.5s;
    border-radius: 8px;
    padding: 35px 10px 0 10px;

    .krest {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 0px;
      right: 0px;
      background-color: transparent;
      border: none;
      border-radius: 8px;
      cursor: pointer;
      img {
        width: 30px;
        height: 30px;
      }
    }
  }
}

