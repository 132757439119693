.layoutBlock {
  position: relative;
  // background: #f5f5f5;
  background: #fff;
  padding: 62px 0 0 0;
}

.saleQr {
  padding: 0 !important;
}
