.returnEvelyProdList {
    min-height: 100vh;
    padding: 8px 0 15px;
    width: 100%;

    &__results {
        padding-top: 5px;

        > p {
            font-size: 18px;
            font-weight: 500;
            color: rgba(47, 71, 190, 0.991);
            padding: 0px 10px;
        }
    }
}