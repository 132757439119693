.saleProdsQR {
  height: 100vh;
  width: 100%;
  padding: 15px 0;

  &__inner {
    height: 100%;

    .header {
      display: flex;
      justify-content: space-between;
      padding: 0 30px 0 0;
    }

    .actionAddProd {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;

      input {
        width: 30%;
        border: 1px solid #e1dede !important;
        box-shadow: none !important;
        cursor: pointer;
        border-radius: 5px;
        height: 38px;
        color: rgba(0, 0, 0, 0.782);
        padding: 3px 15px;
        font-size: 18px;
        width: 400px;

        &::placeholder {
          color: #6b72809e;
        }

        &:focus {
          border: 1px solid rgba(89, 89, 89, 0.529);
          outline: none;
        }
      }
    }

    .standartTable {
      margin: 20px 0 0 0;
    }
  }

  &__table {
    height: calc(100% - 60px);

    .delIcon {
      display: flex;
      justify-content: center;

      button {
        background: transparent;
        width: 20px;
        height: 20px;

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

.actionsAddProd {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-end;

  .inputSend {
    width: 100%;
  }

  button {
    width: 100%;
  }
}
.saveAction {
  height: 38px;
  background: #299b31;
  padding: 0px 20px 0px;
  border-radius: 5px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  p {
    font-size: 14px;
    font-weight: 500;
  }
}
