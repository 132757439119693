.blockSearch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    min-width: 100%;
    padding: 0px 7px;

    input {
        height: 35px;
        border-radius: 5px;
        padding: 0 10px 0 15px;
        margin: 10px 0;
        font-size: 16px;
        font-weight: 400;
        color: #000;
        width: 100%;
        border: none;
        background: transparent;

        &::placeholder {
            color: #222;
        }

        &:focus {
            border: none;
            outline: none;
        }
    }

    .navBtn {
        width: 25px;
        min-width: 25px;
        height: 25px;
    }

    button {
        width: 30px;
        min-width: 30px;
        height: 30x;
        background: transparent;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 100%;
            height: 100%;
        }
    }
}