.generateBlock {
  display: flex;
  align-items: center;

  .modal {
    &__inner {
      width: 50%;
      top: 50% !important;

      > h6 {
        padding: 12px 40px 8px 12px !important;
        font-size: 14px;
        font-weight: 500;
        // color: #4361ee;
        // display: none;
      }

      .content {
        padding: 5px !important;

        iframe {
          height: 85vh !important;
          width: 100%;
        }
      }
    }
  }

  .saveAction {
    background: #1976d2;
  }
}
