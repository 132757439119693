.parentBlockSpending {
    background-color: #ebeef2;
    padding: 10px 10px 80px;
    min-height: 93vh;

    .inputBlock {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 5px;

        .selectBlock {
            background-color: #f5f5f5;
            margin-bottom: 10px;
            border-radius: 5px;
            position: relative;
            width: 55%;
            min-width: 55%;
            height: 45px;

            .selectBlockMain {
                width: 100%;
                height: 45px;

                .selectBlock {
                    width: 100%;
                    height: 45px;

                    &__inner {
                        height: 45px;
                        border-radius: 5px;
                    }

                    .selectBlock__activeBlock  {
                        top: 50px;
                    }
                }
            }
        }

        > input {
            background-color: #fff;
            height: 45px;
            border: 1px solid rgba(47, 71, 190, 0.287);
            border-radius: 5px;
            padding: 0px 10px;
            font-size: 15px;
            width: 20%;
            min-width: 20%;
            &::placeholder {
                font-size: 14px;
                color: #cdcdcd;
            }
            
            &:focus {
                border: 1px solid rgba(47, 71, 190, 0.287) !important;
                outline: 1px solid rgba(47, 71, 190, 0.287);
            }
        }

        > button {
            min-width: 19%;
            font-size: 26px;
            line-height: 30px;
            margin: 0;
            background-color: rgba(12, 169, 70, 0.9);
            color: #fff;
            height: 45px;
            border-radius: 5px;
        }
    }

    .inputComment {
        width: 100%;
        height: 45px;

        input {
            width: 100%;
            height: 45px;
            padding: 5px 20px;
            border-radius: 5px;
            border: 1px solid rgba(47, 71, 190, 0.287);
            font-size: 15px;

            &::placeholder {
                font-size: 14px;
                color: #cdcdcd;
            }

            &:focus {
                border: 1px solid rgba(47, 71, 190, 0.287) !important;
                outline: 1px solid rgba(47, 71, 190, 0.287);
            }
        }
    }

    .mainTitleSpernding {
        font-size: 20px;
        font-weight: 600;
        padding: 15px 0px;
    }
}
  
