.everyProdPay {
    padding: 10px 8px;
    background-color: rgba(212, 223, 238, 0.47);
    border-radius: 5px;
    border: 1px solid rgba(47, 71, 190, 0.107);
    width: 97%;
    margin: 8px auto;

    &__inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;

        .blockTitlePay {
            width: 67%;

            &__inner {
                display: flex;
                flex-direction: row;
                gap: 7px;
                align-items: center;

                > p {
                    font-size: 15px;
                    font-weight: 600;
                    color: rgba(47, 71, 190, 0.687);
                    line-height: 17px;
                }
            }

            span {
                display: block;
                font-size: 13px;
                font-weight: 400;
                color: rgba(12, 169, 70, 0.9);
                line-height: 15px;
                margin-top: 5px;
            }

            b {
                display: block;
                font-size: 14px;
                font-weight: 400;
                margin-top: 5px;
            }
        }

        .status {
            width: 27%;

            p {
                color: rgba(12, 169, 70, 0.9);
                font-size: 14px;
                font-weight: 500;
            }
            
            span {
                display: block;
                margin-top: 3px;
                font-size: 14px;
                font-weight: 400;
                color: rgba(12, 169, 70, 0.9);
            }
        }
    }
}