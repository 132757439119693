.scanner {
    height: 91vh;
    min-width: 100%;
}

#qrCodeContainer {
    position: relative;
    height: 100%;
    min-width: 100%;
    border-radius: 7px;
    overflow: hidden;
}

#qr-shaded-region {
    border-width: 80px !important;
    border-top-width: 230px !important;
    border-bottom-width: 230px !important;
}

video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto !important;
    min-width: 100%;
    min-height: 100vh;
    transform: translate(-50%,-50%);
}
